<template>
    <div>
        <v-container>
          <v-card style="background-color: #F5F5F5" elevation="3">
            <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
              <span v-if="isNew">Nueva</span>
              <span v-if="!isNew">Editar</span>
              pregunta
            </div>

            <v-form
              ref="form"
              v-model="isValid"
              autocomplete="off"
            >
            <v-tabs v-model="tab" background-color="transparent" show-arrows>
              <v-tabs-slider color="blue"></v-tabs-slider>
              <v-tab href="#tab-main">Preguntas</v-tab>
              <v-tab href="#tab-dir">Tiendas</v-tab>
              <v-tab href="#tab-res">Respuestas</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless>
              <v-tab-item :value="'tab-main'">
                <v-card style="background-color: #F5F5F5" flat>
                  <!-- FORMULARIO PREGUNTA -->
                  <v-row class="py-2">
                    <v-col class="px-7 mt-4" cols="12" sm="8" md="6" lg="5" xl="4">
                      <v-text-field
                          class="mb-n7"
                          outlined
                          dense
                          text
                          v-model="pregunta.Descripcion"
                          label="Descripción"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  
                  <div class="py-4 px-5">
                    <!-- Boton añadir pregunta -->
                    <v-btn
                        :loading="loading"
                        :disabled="editNewLinea"
                        @click="onBtnNuevaLinea"
                        color="primary" small class="mb-2">
                        Añadir pregunta
                    </v-btn>
                    <v-data-table
                        :headers="lineasHeaders"
                        :items="pregunta.Lineas"
                        :options.sync="lineasOptions"
                        :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                        :loading="loading"
                        style="overflow: scroll"
                        mobile-breakpoint="0"
                        class="elevation-1"
                        dense>
                        <!-- eslint-disable-next-line vue/valid-v-slot -->
                        <template v-slot:item.Texto="{ item }">
                            <v-text-field outlined dense text hide-details
                              v-model="item.Texto"
                          ></v-text-field>
                        </template>
                        <!-- eslint-disable-next-line vue/valid-v-slot -->
                        <template v-slot:item.Descripcion="{ item }">
                            <v-text-field outlined dense text hide-details
                              v-model="item.Descripcion"
                          ></v-text-field>
                        </template>
                        <!-- eslint-disable-next-line vue/valid-v-slot -->
                        <template v-slot:item.Observaciones="{ item }">
                            <v-simple-checkbox v-ripple v-model="item.Observaciones"></v-simple-checkbox>
                        </template>
                        <!-- eslint-disable-next-line vue/valid-v-slot -->
                        <template v-slot:item.Opt="{ item }">
                            <v-btn
                            color="error"
                            class="mr-2 my-1"
                            small
                            @click.stop="onBtnShowLineaDeleteModal(item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <!-- NUEVA LINEA FORMULARIO -->
                        <template v-if="editNewLinea" slot="body.append">
                            <tr class="new-row">
                                <td>
                                    <v-text-field outlined dense text hide-details
                                        v-model="newLinea.Texto"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field outlined dense text hide-details
                                        v-model="newLinea.Descripcion"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-simple-checkbox v-ripple v-model="newLinea.Observaciones"></v-simple-checkbox>
                                </td>
                                <td style="min-width: 250px" align ="right">
                                    <v-btn
                                        :loading="loading"
                                        :disabled="newLinea == null"
                                        @click="onBtnSaveNuevaLinea"
                                        color="primary"
                                        class="mt-2 mb-4 mr-2"
                                        small>
                                        Aceptar
                                    </v-btn>
                                    
                                    <v-btn
                                        @click="onBtnCancelNuevaLinea"
                                        class="mt-2 mb-4 mr-2"
                                        small>
                                        Cancelar
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                  </div>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-dir'">
                <v-card style="background-color: #F5F5F5" flat>
                  <div class="py-3 px-2">
                    <!-- <ListSelector
                      :dataList="dirList"
                      v-model="pregunta.DirValue" :itemTitle="'DirNom'" :itemValue="'DirId'">
                    </ListSelector> -->
                    <ListSelector
                      :dataList="perCatList"
                      v-model="pregunta.PerCatValue" :itemTitle="'PerCatNom'" :itemValue="'PerCatId'">
                    </ListSelector>
                  </div>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-res'">
                <div class="py-2 px-4" style="background-color: #F5F5F5">
                    <h2>TOTAL RESPUESTAS: {{totalDataRows}}</h2>
                  </div> 
                <v-card style="background-color: #F5F5F5" flat>
                  
                  <div class="py-2 px-4" v-for="(respuesta, i) in respuestas" :key="i">
                      <h3>Tienda: {{respuesta.DirNom}}</h3>
                      <div v-for="(res, i2) in respuesta.Respuestas" :key="i2">
                          <h4>{{res.Linea.Texto}}</h4>
                          <p>Fecha respuesta: {{formatDate(res.FechaEnvio)}} - Respondido por: {{res.Per.PerNom}}</p>
                          <p>Respuesta: {{res.Respuesta}}</p>
                          <p v-if="res.Linea.Observaciones">Observaciones: {{res.Observaciones}}</p>
                      </div>
                      <hr/>
                  </div>
                  <div class="py-2 px-4">
                      <span v-if="respuestas.length === 0">Todavía no hay respuestas registradas</span>
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <!-- ACEPTAR / CANCELAR / BORRAR -->
            <v-footer app bottom fixed padless>
              <v-row class="my-1" justify="center">
                  <v-btn
                      :loading="loading"
                      :disabled="!isValid"
                      @click="onBtnSave"
                      color="primary"
                      class="mt-2 mb-4 mr-2"
                      style="width:100px"
                  >
                      Aceptar
                  </v-btn>
                  
                  <v-btn
                      class="mt-2 mb-4 mr-2"
                      style="width:100px"
                      @click="goToList"
                  >
                      Cancelar
                  </v-btn>

                  <v-btn v-if="!isNew"
                      color="error"
                      class="mt-2 mb-4"
                      style="width:100px"
                      @click="onBtnShowDeleteModal()"
                  >
                      Borrar
                  </v-btn>
              </v-row>
            </v-footer>
            </v-form>
          </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar Pedido -->
        <v-dialog
          v-model="showDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR PREGUNTA
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar el la pregunta? Esta acción no se podrá deshacer.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="primary"
                @click="onBtnDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Borrar linea -->
        <v-dialog
            v-model="showLineaDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR PREGUNTA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la pregunta?

                    <ul v-if="selectedLinea != null">
                      <li>Texto: {{selectedLinea.Texto}}</li>
                      <li>Descripción: {{selectedLinea.Descripcion}}</li>
                      <li>Observaciones: {{selectedLinea.Observaciones ? 'Sí' : 'No'}}</li>
                    </ul>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        color="primary"
                        @click="onBtnLineaDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelLineaDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import funcs from "../../funcs";
import { mapState } from "vuex";
// import DateFind from "../../components/DateFind.vue";
import ListSelector from "../../components/ListSelector.vue";
export default ({
    components: {
        ListSelector
    },
    computed: {
        ...mapState(['empId', 'perId', 'esDepartamento', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            showDeleteDialog: false,
            pregunta: {
                PreguntaId: 0,
                // DirValue: [],
                PerCatValue: [],
                Lineas: []
            },
            // dirList: [],
            perCatList: [],
            respuestas: [],
            // Tabla de lineas
            showLineaDeleteDialog: false,
            lineasOptions: {itemsPerPage: 100},
            lineasHeaders: [
                { text: 'Texto pregunta', width: 400, sortable: false, value: 'Texto' },
                { text: 'Descripción', width: 200, sortable: false, value: 'Descripcion' },
                { text: 'Observaciones', width: 100, sortable: false, value: 'Observaciones' },
                { text: '', width: 100, sortable: false, value: 'Opt', align: 'end' }
            ],
            editNewLinea: false, // Indica si se esta editando la nueva linea
            newLinea: {},
            selectedLinea: null // Indica la linea seleccionada para el borrado
        }
    },
    // watch: {
      // test: {
      //   handler () {
      //       this.validate();
      //   },
      //   deep: true,
      // },
    // },
    mounted() {
      if (!this.esDepartamento) { this.$router.push('/'); return; }
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.pregunta.PreguntaId = this.$route.params.id;
        this.loadData();
      } 
      //   else {
      //   }

      // this.getAllDirs()
      // .then(data => {
      //   this.dirList = data.items;
      // });
      this.getAllPerCats()
      .then(data => {
        this.perCatList = data.items;
      });
      this.getResponsesFromApi()
      .then(data => {
        this.respuestas = data.items;
        this.totalDataRows = data.total;
      });
    },
    methods: {
      goToList() {
        this.$router.push('/preguntas');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            PreguntaId: this.pregunta.PreguntaId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/preguntas/get", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Pregunta;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item,
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar la pregunta.');
                this.goToList();
            }
          });
        })
      },
      // getAllDirs() {
      //   return new Promise((resolve) => {
      //     const controllerParameters = {   
      //         Action: "GET_DATA_LIST_PAGINATED",
      //         NumRegsPag: 2000,
      //         NumPag: 1,
      //         EmpId: this.empId,
      //         Searcher: true
      //     };
          
      //     var AuthToken = localStorage.getItem('token');  
      //     axios({ method: "POST", "url": this.urlRaiz + "/api/dir", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
      //     .then(result => {
      //       const items = result.data.EntsList;
      //         setTimeout(() => {
      //             resolve({
      //               items,
      //             })
      //         }, 1000)
      //       })
      //       .catch(error => {
      //         if(error != null) {
      //             alert('Error al cargar las tiendas.');
      //         }
      //       });
      //   })
      // },
      getAllPerCats() {
        return new Promise((resolve) => {
          const controllerParameters = {   
              Action: "GET_DATA_LIST_PAGINATED",
              NumRegsPag: 2000,
              NumPag: 1,
              EmpId: this.empId,
              Searcher: true,
              PerCatIdAnyLevel: 102 // TIENDAS
          };
          
          var AuthToken = localStorage.getItem('token');  
          axios({ method: "POST", "url": this.urlRaiz + "/api/percat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
              setTimeout(() => {
                  resolve({
                    items,
                  })
              }, 1000)
            })
            .catch(error => {
              if(error != null) {
                  alert('Error al cargar las tiendas.');
              }
            });
        })
      },
      getResponsesFromApi() {
        return new Promise((resolve) => {
          const controllerParameters = {
            PreguntaId: this.pregunta.PreguntaId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/preguntas/get-responses", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            //const items = result.data.Respuestas;
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  items,
                  total
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                alert('Error al cargar las respuestas.');
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            PreguntaId: this.pregunta.PreguntaId,
            EmpId: this.empId,
            Descripcion: this.pregunta.Descripcion,
            // DirIds: this.pregunta.DirValue,
            PerCatIds: this.pregunta.PerCatValue,
            Lineas: this.pregunta.Lineas
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/preguntas/save", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Pregunta;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar la pregunta.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            PreguntaId: this.pregunta.PreguntaId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/preguntas/delete", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar la pregunta.');
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Pregunta no encontrada.');
              this.goToList();
              return;
            }

            this.pregunta = data.item;
            // const DirValue = this.pregunta.Dir.map(m => m.DirId)
            // this.pregunta.DirValue = DirValue
            const PerCatValue = this.pregunta.PerCat.map(m => m.PerCatId);
            this.pregunta.PerCatValue = PerCatValue;
          });
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.postDataToApi().then(data => {
            if(data.item.PreguntaId != null) {
                this.goToList();
            } else {
              if (data.item != '') {
                alert(data.item);
              } else {
                alert('Error al guardar la pregunta.');
              }
            }
          });
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          } else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region Tabla preguntas
      onBtnNuevaLinea() {
        this.newLinea = {};
        this.editNewLinea = true;
      },
      onBtnCancelNuevaLinea() {
        this.newLinea = null;
        this.editNewLinea = false;
      },
      onBtnSaveNuevaLinea() {
        if(this.newLinea == null) { return; }
        this.pregunta.Lineas.push(this.newLinea);
        this.newLinea = null,
        this.editNewLinea = false;
      },
      onBtnLineaDelete() {
        var index = this.pregunta.Lineas.indexOf(this.selectedLinea);
        if (index != -1) {
          this.pregunta.Lineas.splice(index, 1);
        }
        this.selectedLinea = null;
        this.showLineaDeleteDialog = false;
      },
      //#endregion

      //#region  Gestión de modales
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowLineaDeleteModal(item) {
        this.selectedLinea = item;
        this.showLineaDeleteDialog = true;
      },
      onBtnCancelLineaDelete() {
        this.selectedLinea = null;
        this.showLineaDeleteDialog = false;
      },
      //#endregion
      formatDate(value)
      {
          return funcs.formatDate(value, true);
      }
    }
})
</script>

<style>
  .perConDt tr.edit-row > td{
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
</style>
